import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsertComponent } from './insert/insert.component';
import { Step1Component } from './insert/step1/step1.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Step2Component } from './insert/step2/step2.component';
import { Step3Component } from './insert/step3/step3.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { GuardService } from './auth/guard.service';
import { AuthService } from './auth/auth.service';
import { HelpComponent } from './help/help.component';
import { UsersComponent } from './manage/users/users.component';
import { RecipientsComponent } from './manage/recipients/recipients.component';
import { IsAdminService } from './auth/is-admin.service';
import { NotAccessComponent } from './not-access/not-access.component';


const routes: Routes = [
    {
        path: 'add',
        component: InsertComponent,
        canActivate: [GuardService],
        children: [
            {
                path: '',
                component: Step1Component
            },
            {
                path: '1',
                component: Step1Component
            },
            {
                path: '1/:cid',
                component: Step1Component
            },
            {
                path: '2',
                component: Step2Component
            },
            {
                path: '2/:cid',
                component: Step2Component
            },
            {
                path: '3',
                component: Step3Component
            },
            {
                path: '3/:cid',
                component: Step3Component,
            }
        ]
    },
    {
        path: 'help',
        component: HelpComponent,
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [GuardService, IsAdminService]
    },
    {
        path: 'recipients',
        component: RecipientsComponent,
        canActivate: [GuardService, IsAdminService]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [GuardService]
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
        canActivate: [GuardService]
    },
    {
        path: 'not-access',
        component: NotAccessComponent,
        canActivate: [GuardService]
    },
    { 
        path: '**', 
        component: PageNotFoundComponent,
        canActivate: [GuardService]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    providers: [AuthService],
    exports: [RouterModule]
})
export class AppRoutingModule { }
