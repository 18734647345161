import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InsertComponent } from './insert/insert.component';
import { Step1Component } from './insert/step1/step1.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Step2Component } from './insert/step2/step2.component';
import { DndModule } from '../../node_modules/ngx-dnd';
import { ImageCropperModule } from '../../node_modules/ngx-image-cropper';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { Wgtm1Component } from './shared/components/wgtm1/wgtm1.component';
import { Wgtm2Component } from './shared/components/wgtm2/wgtm2.component';
import { Wgtm3Component } from './shared/components/wgtm3/wgtm3.component';
import { Wgtm4Component } from './shared/components/wgtm4/wgtm4.component';
import { Wgtm5Component } from './shared/components/wgtm5/wgtm5.component';
import { Wgtm6Component } from './shared/components/wgtm6/wgtm6.component';
import { Wgtm7Component } from './shared/components/wgtm7/wgtm7.component';
import { Step3Component } from './insert/step3/step3.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/auth.service';
import { GuardService } from './auth/guard.service';
import { SafePipe } from './shared/pipes/safe.pipe';

import { NgxEditorModule } from 'ngx-editor';

import { NgxCropperjsModule } from 'ngx-cropperjs';
import { NgxImgModule } from 'ngx-img';
import { Wgtm8Component } from './shared/components/wgtm8/wgtm8.component';
import { Wgtm9Component } from './shared/components/wgtm9/wgtm9.component';
import { Wgtm10Component } from './shared/components/wgtm10/wgtm10.component';
import { Wgtm11Component } from './shared/components/wgtm11/wgtm11.component';
import { Wgtm12Component } from './shared/components/wgtm12/wgtm12.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CropComponent } from './shared/components/cropper/crop.component';
import { HelpComponent } from './help/help.component';
import { Wgtm13Component } from './shared/components/wgtm13/wgtm13.component';
import { Wgtm14Component } from './shared/components/wgtm14/wgtm14.component';
import { UsersComponent } from './manage/users/users.component';
import { RecipientsComponent } from './manage/recipients/recipients.component';
import { NotAccessComponent } from './not-access/not-access.component';

import { ChartsModule, ThemeService } from 'ng2-charts';

import { QuillModule } from 'ngx-quill'

@NgModule({
  declarations: [
    AppComponent,
    InsertComponent,
    Step1Component,
    PageNotFoundComponent,
    Step2Component,
    Wgtm1Component,
    Wgtm2Component,
    Wgtm3Component,
    Wgtm4Component,
    Wgtm5Component,
    Wgtm6Component,
    Wgtm7Component,
    Step3Component,
    DashboardComponent,
    HeaderComponent,
    LoginComponent,
    SafePipe,
    Wgtm8Component,
    Wgtm9Component,
    Wgtm10Component,
    Wgtm11Component,
    Wgtm12Component,
    CropComponent,
    HelpComponent,
    Wgtm13Component,
    Wgtm14Component,
    UsersComponent,
    RecipientsComponent,
    NotAccessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DndModule.forRoot(),
    ImageCropperModule,
    NgxEditorModule,
    AngularCropperjsModule,
    NgxCropperjsModule,
    NgxImgModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ChartsModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': ['', 'center', 'right'] }],
          ['clean']
        ]
      },
      theme: 'snow'
    })
  ],
  providers: [GuardService, AuthService, ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
