import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Widget } from '../../models/widget';

@Component({
    selector: 'app-wgtm6',
    templateUrl: './wgtm6.component.html',
    styleUrls: ['./wgtm6.component.scss']
})
export class Wgtm6Component implements OnInit {

    @Input()
    wgtData: Widget;

    @Input()
    wgtIndex: number;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    public widgetData: Widget = {
        nid: 'M6',
        name: 'CTA section',
        icon: './assets/img/m6.svg',
        fieldsGroup: [
            {
                group: [
                    {
                        type: 'text',
                        name: 'cta_label',
                        placeholder: 'Write cta label here...',
                        label: 'CTA Label',
                        value: '',
                        required: false
                    },
                    {
                        type: 'text',
                        name: 'cta_url',
                        placeholder: 'Write cta url here...',
                        label: 'CTA URL',
                        value: '',
                        required: false
                    }
                ]
            },
            {
                group: [
                    {
                        type: 'text',
                        name: 'cta_label',
                        placeholder: 'Write cta label here...',
                        label: 'CTA Label',
                        value: '',
                        required: false
                    },
                    {
                        type: 'text',
                        name: 'cta_url',
                        placeholder: 'Write cta url here...',
                        label: 'CTA URL',
                        value: '',
                        required: false
                    }
                ]
            },
            {
                group: [
                    {
                        type: 'text',
                        name: 'cta_label',
                        placeholder: 'Write cta label here...',
                        label: 'CTA Label',
                        value: '',
                        required: false
                    },
                    {
                        type: 'text',
                        name: 'cta_url',
                        placeholder: 'Write cta url here...',
                        label: 'CTA URL',
                        value: '',
                        required: false
                    }
                ]
            }
        ]
    };


    constructor() {}

    ngOnInit() {
        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.fieldsGroup) {
            this.widgetData = this.wgtData;
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }

}
