import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from '../shared/models/user';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GuardService implements CanActivate {

    private checked = false;

    constructor(private authService: AuthService, private router: Router) {
        console.log('Goard constructor');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const url = state.url;
        if (!this.checked) {
            return this.authService.userObs.subscribe((u: User) => {
                if (u) {
                    if (Object.entries(u).length > 0) {
                        
                        console.log('Guards ok');
                        this.router.navigate([url]);
                        this.checked = true;
                        return true;
                    } else {
                        console.log('Guards no ok');
                        // return false;
                    }
                }
            });
        } else {
            return true;
        }
        // return true;
    }

}
