import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CropperComponent } from 'angular-cropperjs';

@Component({
    selector: 'app-crop',
    templateUrl: './crop.component.html',
    styleUrls: ['./crop.component.scss']
})
export class CropComponent implements OnInit {

    @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;

    @Input()
    config: any;

    @Input()
    file: any;

    public imageDestination: string;

    @Output()
    fileBase64 = new EventEmitter<string>();

    constructor() {
        this.imageDestination = '';
    }

    ngOnInit() {

    }
    getCroppedImage() {
        const img = this.angularCropper.cropper.getCroppedCanvas(
            {
                width: this.config.croppedWidth, 
                height: this.config.croppedHeight,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'
            }
        ).toDataURL();

        this.imageDestination = (img ? img : '');
        this.fileBase64.emit(this.imageDestination);
        this.imageDestination = '';

        this.angularCropper.cropper.destroy();
    }

}
