import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { UsersService } from 'src/app/shared/services/users.service';
import { NgbModal, NgbModalConfig, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Recipients } from 'src/app/shared/models/recipients';
import { CampaingService } from 'src/app/shared/services/campaing.service';
import crypt from '../../../../node_modules/crypto-js';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [UsersService]
})
export class UsersComponent implements OnInit {

    readonly sekretKey = 'templateEMG';

    public loading = true;

    public usersList: User[];

    public recipients: Recipients[];
    public selectedRecipients: string[] = [];

    public userFormData = new userFormData();
    public updateUserFormData = new userFormData();

    // Modal
    closeResult: string;
    @ViewChild('addNew', {static: true}) private addNewModal;
    @ViewChild('success', {static: true}) private successModal;
    @ViewChild('delete', {static: true}) private deleteModal;
    public errorMessage: string = '';
    modalOption: NgbModalOptions = {}; // not null!
    public successModalMessage: string;
    public userToDeleteID: User;

    // Logged user
    public loggedUser: User;

    constructor(private authService: AuthService, private campaingService: CampaingService, private usersService: UsersService, private modalService: NgbModal) {
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;

        this.usersService.getAllUsersObs().subscribe((resp: User[]) => {
            if (Object.keys(resp).length > 0) {
                this.usersList = resp;

                this.loading = false;
            }
        });

        this.campaingService.getRecipientsObs().subscribe((resp: Recipients[]) => {
            if (Object.keys(resp).length > 0) {
                this.recipients = resp;
            } else {
                console.log('Pobieranie odbiorców...');
            }
        });

        this.authService.userObs.subscribe((u: User) => {
            if (u) {
                if (Object.entries(u).length > 0) {
                    this.loggedUser = u;
                }
            }
        });
    }

    ngOnInit() {
        // console.log(crypt.AES.decrypt('U2FsdGVkX19cBL2fZ0qn5wsNqtxJHKWssLPunkf4TQI=', this.sekretKey).toString(crypt.enc.Utf8));
        // console.log(crypt.AES.encrypt('bp6478_It', this.sekretKey).toString());
    }

    //Modal
    open(content, row) {
        if (row) {
            this.selectedRecipients = row.recipients;
            this.updateUserFormData = row;
            this.updateUserFormData.password = crypt.AES.decrypt(row.password, this.sekretKey).toString(crypt.enc.Utf8);
        }
        this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          console.log(result);
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        console.log(reason);
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    addNewUser(formData) {
        const userData = {
            name: formData.form.controls.userName.value,
            email: formData.form.controls.userMail.value,
            role: formData.form.controls.userRole.value,
            password: formData.form.controls.userPassword.value,
            recipients: this.selectedRecipients
        }

        this.usersService.addNewUser(userData);

        this.usersService.getErrorMessageObs().subscribe(resp => {
            if (resp.length > 0) {
                if (resp !== 'success') {
                    this.errorMessage = resp;

                    setTimeout(() => {
                        this.errorMessage = '';
                    }, 4000);
                } else {
                    this.errorMessage = '';
                    this.userFormData = new userFormData();
                    this.selectedRecipients = [];
                    this.modalService.dismissAll();
                    this.successModalMessage = 'The user has been added correctly!';
                    this.modalService.open(this.successModal, this.modalOption);

                    setTimeout(() => {
                        this.modalService.dismissAll();
                    }, 4000);
                }
            }
        });
    }

    updateUser(formData) {
        if (this.updateUserFormData.UID) {
            const userData = {
                UID: this.updateUserFormData.UID,
                name: formData.form.controls.userName.value,
                email: formData.form.controls.userMail.value,
                role: formData.form.controls.userRole.value,
                password: formData.form.controls.userPassword.value,
                recipients: this.selectedRecipients
            }

            this.usersService.updateUser(userData);
            this.updateUserFormData = new userFormData();
            this.selectedRecipients = [];
            this.modalService.dismissAll();
            this.successModalMessage = 'The user data has been updated correctly!';
            this.modalService.open(this.successModal, this.modalOption);

            setTimeout(() => {
                this.modalService.dismissAll();
            }, 4000);
        } else {
            this.errorMessage = 'Server error. Please try again later.';

            setTimeout(() => {
                this.errorMessage = '';
            }, 4000);
        }
    }
    
    deleteUser(userData) {
        this.userToDeleteID = userData;
        this.modalService.open(this.deleteModal, this.modalOption);
    }

    removeUser() {
        if (this.userToDeleteID.UID) {
            const userData = {
                UID: this.userToDeleteID.UID,
                name: this.userToDeleteID.name,
                email: this.userToDeleteID.email,
                role: this.userToDeleteID.role,
                password: crypt.AES.decrypt(this.userToDeleteID.password, this.sekretKey).toString(crypt.enc.Utf8),
                recipients: this.userToDeleteID.recipients,
            }

            this.usersService.removeUser(userData);
            this.userToDeleteID;
            this.modalService.dismissAll();
            this.successModalMessage = 'The user has been deleted correctly!';
            this.modalService.open(this.successModal, this.modalOption);
            

            setTimeout(() => {
                this.modalService.dismissAll();
            }, 4000);
        }
    }

    updateRecipients(value) {
        const symbol = value.srcElement.value;
        if (this.selectedRecipients.indexOf(symbol) === -1) {
            this.selectedRecipients.push(symbol);
        } else {
            this.selectedRecipients = this.selectedRecipients.filter(r => r !== symbol);
        }
    }
}


class userFormData {
    constructor(
        public UID?: string,
        public name?: string,
        public recipients?: string[],
        public email?: string,
        public role?: string,
        public created?: Date,
        public password?: string
    ) {}
}