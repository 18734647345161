import { Component, OnInit } from '@angular/core';
import { Widget } from '../shared/models/widget';
import { CampaingService } from '../shared/services/campaing.service';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

    public components: Widget[] = [];

    constructor(private campaingService: CampaingService) { 
        this.campaingService.getComponentsObs().subscribe((components: Widget[]) => {
            if (components && components.length > 0) {
                this.components = components;
            } else {
                console.log('Pobieranie dostępnych komponentów...');
            }
        });
    }

    ngOnInit() {
    }

}
