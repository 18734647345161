import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Widget } from '../../models/widget';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-wgtm8',
    templateUrl: './wgtm8.component.html',
    styleUrls: ['./wgtm8.component.scss']
})
export class Wgtm8Component implements OnInit {

    protected uploadURL = environment.uploadURL;

     // Image cropper
     public imageChangedEvent: any = '';
     public wgtErrorMessage: string;
     public wgtError: boolean;
     public wgtErrorClass: string;

    @Input()
    wgtData: Widget;

    @Input()
    wgtIndex: number;

    @Input()
    cid: string;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    imgSrc: any = [];

    public widgetData: Widget = {
        nid: 'M8',
        name: 'Custom hero',
        icon: './assets/img/m8.svg',
        file: {
            name: 'img',
            placeholder: '',
            label: 'Image',
            size: {
                w: 650,
                h: 325
            },
            value: '',
            required: false
        },
        fields: [
            {
                type: 'text',
                name: 'image_url',
                placeholder: 'Write image url here...',
                label: 'Image URL',
                value: '',
                required: false
            },
            {
                type: 'text',
                name: 'image_alt',
                placeholder: 'Write image alternative text here...',
                label: 'Image ALT',
                value: '',
                required: false
            }
        ]
    };


    constructor(private http: HttpService) {}

    ngOnInit() {
        this.wgtError = false;
        this.wgtErrorMessage = '';
        this.wgtErrorClass = '';

        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.file || this.wgtData.fields) {
            this.widgetData = this.wgtData;

            // Editing
            if (this.widgetData.file.value) {
                if (this.widgetData.file.value.length > 0) {
                    const src = this.widgetData.file.value;
                    this.imgURLToBlob(src);
                }
            }
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }
    
    //Cropper
    fileChangeEvent(event: any): any {
        this.wgtError = false;
        this.wgtErrorMessage = '';
        this.wgtErrorClass = '';

        if (event.target.files && event.target.files[0]) {
            // Size Filter Bytes
            const max_size = 2000000; // 2MB
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 650;
            const max_width = 650;
 
            if (event.target.files[0].size > max_size) {
                this.wgtError = true;
                this.wgtErrorMessage = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
                this.wgtErrorClass = 'bg-danger text-white show';
                this.hideError();
                return false;
            }

            if (!allowed_types.includes(event.target.files[0].type)) {
                this.wgtError = true;
                this.wgtErrorMessage = 'Only Images are allowed ( JPG | PNG )';
                this.wgtErrorClass = 'bg-danger text-white show';
                this.hideError();
                return false;
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = image.height;
                    const img_width = image.width;
 
                    if (img_height > max_height && img_width > max_width) {
                        this.wgtError = true;
                        this.wgtErrorMessage = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
                        this.wgtErrorClass = 'bg-danger text-white show';
                        this.hideError();
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.imageChangedEvent = imgBase64Path;
                        
                        this.wgtError = false;
                        this.wgtErrorMessage = '';
                        this.wgtErrorClass = '';

                        this.base64ToBlob(imgBase64Path);
                    }
                };
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    private hideError() {
        setTimeout(() => {
            this.wgtError = false;
            this.wgtErrorMessage = '';
            this.wgtErrorClass = '';
        }, 2000);
    }

    base64ToBlob(obj) {
        const BASE64_MARKER = ';base64,';
        const parts = obj.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        
        const blob = new Blob([uInt8Array], { type: contentType });

        this.widgetData.file.value = blob as any;

        this.updateWidget();
    }

    imgURLToBlob(obj) {

        const url = this.uploadURL + '/' + this.cid + '/' + obj;

        this.http.getImageBlob(url).subscribe(resp => {
            this.widgetData.file.value = resp as any;
            this.updateWidget();
        });
    }

    isABlob(obj): boolean { 
        return obj.constructor === Blob;
    }

}
