import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value, type: string) {
        switch (type) {
			case 'html': return this.sanitized.bypassSecurityTrustHtml(value);
			case 'style': return this.sanitized.bypassSecurityTrustStyle('url(' + URL.createObjectURL(value) + ')');
			case 'script': return this.sanitized.bypassSecurityTrustScript(value);
			case 'url': return this.sanitized.bypassSecurityTrustUrl(URL.createObjectURL(value));
			case 'resourceUrl': return this.sanitized.bypassSecurityTrustResourceUrl(value);
			default: throw new Error(`Invalid safe type specified: ${type}`);
		}
    }
}