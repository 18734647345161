import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/shared/models/user';
import { Router } from '@angular/router';
import * as CryptoJS from 'node_modules/crypto-js';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public loginStatus: boolean = true;

    constructor(private authService: AuthService, public router:Router) {
        this.authService.userObs.subscribe((u: User) => {
            if (u) {
                this.router.navigate(['/dashboard']);
            }
        });
    }

    ngOnInit() {
    }

    login(formData: NgForm) {
        this.authService.login(formData.value.email, formData.value.password);
        this.authService.loginStatusObs.subscribe((status: string) => {
            if (status === 'fail') {
                this.loginStatus = false;
            } else if (status === 'success') {
                location.reload();
                this.router.navigate(['/dashboard']);
            }
        });
    }

}
