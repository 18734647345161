import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Widget } from '../../models/widget';

@Component({
    selector: 'app-wgtm2',
    templateUrl: './wgtm2.component.html',
    styleUrls: ['./wgtm2.component.scss']
})
export class Wgtm2Component implements OnInit {

    @Input()
    wgtData: Widget;

    @Input()
    wgtIndex: number;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    public widgetData: Widget = {
        nid: 'M2',
        name: 'Media + title + lead',
        icon: './assets/img/m2.svg',
        fields: [
            {
                type: 'text',
                name: 'title',
                label: 'Tytuł',
                placeholder: 'Write title here...',
                value: '',
                required: true
    
            },
            {
                type: 'textarea',
                name: 'description',
                placeholder: 'Write content here...',
                label: 'Opis',
                value: '',
                required: true
            },
            {
                type: 'text',
                name: 'cta_label',
                placeholder: 'Write cta label here...',
                label: 'CTA Label',
                value: '',
                required: false
            },
            {
                type: 'text',
                name: 'cta_url',
                placeholder: 'Write cta url here...',
                label: 'CTA URL',
                value: '',
                required: false
            }
        ]
    };


    constructor() {}

    ngOnInit() {
        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.fields) {
            this.widgetData = this.wgtData;
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }
}
