import { CampaingService } from 'src/app/shared/services/campaing.service';
import { Widget } from 'src/app/shared/models/widget';
import { Component, ViewChild } from '@angular/core';
import { Campaing } from 'src/app/shared/models/campaing';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Recipients } from 'src/app/shared/models/recipients';

@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss']
    
})
export class Step2Component {

    public loading = true;

    public availableComponents: Widget[];

    public components: Widget[] = [];

    public campaing: Campaing;

    private itemToDelete: Widget;

    public editingID: string;

    // Modal
    closeResult: string;
    @ViewChild('aggre', {static: true}) private aggreModal;

    constructor(
        private campaingService: CampaingService, 
        public router:Router,
        private modalService: NgbModal,
        private route: ActivatedRoute) {

        this.editingID = this.route.snapshot.paramMap.get('cid');

        if (this.editingID) {
            // this.campaingService.editCampaing(this.editingID);
        }
            
        this.campaingService.getComponentsObs().subscribe((components: Widget[]) => {
            if (components && components.length > 0) {
                this.availableComponents = components;
                this.loading = false;
            } else {
                console.log('Pobieranie dostępnych komponentów...');
            }
        });

        this.campaingService.getCampaingObs().subscribe((campaing: Campaing) => {
            if (Object.keys(campaing).length > 0) {
                this.campaing = campaing;
                
                if (this.campaing.widgets) {
                    this.components = this.campaing.widgets;
                }
            } else {
                console.log('Reset kampanii...');
                this.router.navigate(['/add/1']);
            }
        });

        
    }

    //Modal
    open(content, event, item) {
        if (event === 'delete') {
            this.itemToDelete = item;
        }
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            if (result === 'delete') {
                this.components = this.components.filter((w: Widget) => w !== this.itemToDelete);
                this.itemToDelete = {};
            }
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }

    addWidget(selectedWgt: Widget) {
        const newWgt = selectedWgt;
        this.components.push(newWgt);
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 300);
    }

    updateComponents(wgt: Widget, index: number) {
        this.components[index] = wgt;
    }

    removeWidget(widget: Widget, wgtList: number) {
        // Usuwanie z listy do wysylki
        this.open(this.aggreModal, 'delete', widget);
    }

    goToNextStep() {
        this.campaingService.addWidgets(this.components);
        if (this.editingID) {
            this.router.navigate(['/add/3', this.editingID]);
        } else {
            this.router.navigate(['/add/3']);
        }
    }

    toggleMainHeader(evt) {
        this.campaing.recipients.checked = evt.srcElement.checked;
    }

    getRecipientsMediaByRid(rid): string {
        let src: Recipients;
        this.campaingService.getRecipientsObs().subscribe((resp: Recipients[]) => {
            if (Object.keys(resp).length > 0) {
                src = resp.filter(r => r.rid === rid)[0];
                this.campaing.recipients.media = src.media;
            } else {
                console.log('Pobieranie odbiorców...');
            }
        });

        return src.media;
    }

}
