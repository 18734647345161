import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from '../../models/user';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public loading = true;

    public userName: string = '';
    public isUserAdmin = false;

    constructor(private authService: AuthService) {
        
    }

    ngOnInit() {
        this.authService.userObs.subscribe((u: User) => {
            if (u) {
                if (Object.entries(u).length > 0) {
                    if (u.name) {
                        if (u.role === 'admin') {
                            this.isUserAdmin = true;
                        }
                        this.userName = u.name;
                        this.loading = false;
                    }
                }
            }
        });
    }

    logout() {
        this.authService.logout();
    }

}
