import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import crypt from '../../../../node_modules/crypto-js';
import { User } from '../models/user';

@Injectable()
export class UsersService {

    readonly sekretKey = 'templateEMG';

    // List
    private usersAll: any[];
    private usersAllObs = new BehaviorSubject<any[]>([]);

    // New
    private newUser: User;
    private newUserObs = new BehaviorSubject<User>({});

    // Message
    private errorMessage: string;
    private errorMessageObs = new BehaviorSubject<string>('');

    constructor(private http: HttpService) {
        this.getAllUsers();
    }

    private getAllUsers() {
        this.http.getDBData('users').subscribe((resp: any[]) => {
            if (resp['status'] !== 'loading') {
                this.usersAll = resp.filter((u: User)=> u.status !== 'deleted');
                this.usersAllObs.next(this.usersAll);
            }
        });
    }

    getAllUsersObs(): Observable<any[]> {
        return this.usersAllObs.asObservable();
    }

    getErrorMessageObs(): Observable<string> {
        return this.errorMessageObs.asObservable();
    }

    addNewUser(user: any) {
        const cryptPass = crypt.AES.encrypt(user.password, this.sekretKey).toString();
        user.password = cryptPass;
        user.created = new Date();
        user.status = 'active';

        let postData: FormData = new FormData(); 

        var data = JSON.stringify(user);
        postData.append('action', 'addNewUser');
        postData.append("postData", data );

        // New user http request
        this.http.addNewUser(postData).subscribe(resp => {
            if (resp['status'] === 'row-exists') {
                this.errorMessage = 'User with email address already exists!';
                this.errorMessageObs.next(this.errorMessage);
            } else if (resp['status'] === 'success') {
                console.log('User added!');
                this.errorMessage = 'success';
                this.errorMessageObs.next(this.errorMessage);

                this.usersAll.push(resp['row']);
                this.usersAllObs.next(this.usersAll);

                setTimeout(() => {
                    this.errorMessage = '';
                    this.errorMessageObs.next(this.errorMessage);
                }, 3000);
            }
        })

        this.newUser = user;
        this.newUserObs.next(this.newUser);
    }

    updateUser(user: any) {
        const cryptPass = crypt.AES.encrypt(user.password, this.sekretKey).toString();
        user.password = cryptPass;
        user.status = 'active';
        user.updated = new Date();

        let postData: FormData = new FormData(); 

        var data = JSON.stringify(user);
        postData.append('action', 'updateUser');
        postData.append("postData", data );

        // New user http request
        this.http.updateUser(postData).subscribe(resp => {
            if (resp === 'success') {
                console.log('User updated!');
            }
        });
    }

    removeUser(user: any) {
        const cryptPass = crypt.AES.encrypt(user.password, this.sekretKey).toString();
        user.password = cryptPass;
        user.status = 'deleted';
        user.updated = new Date();

        let postData: FormData = new FormData(); 

        var data = JSON.stringify(user);
        postData.append('action', 'updateUser');
        postData.append("postData", data );

        // New user http request
        this.http.updateUser(postData).subscribe(resp => {
            if (resp === 'success') {
                this.usersAll = this.usersAll.filter((u: User) => u.UID !== user.UID);
                this.usersAllObs.next(this.usersAll);
                console.log('User deleted!');
            }
        });
    }
}
