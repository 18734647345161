import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    protected restAPIUrl = environment.restAPI;
    protected uploadUrl = environment.uploadURL;
    protected dbURL = environment.dbURL;
    protected APIUrl = this.restAPIUrl + '/rest.php';

    constructor(private http: HttpClient) { }

    createDocument(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'text', reportProgress: true});
    }

    getSingleCampaign(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'json', reportProgress: true});
    }

    getDBData(fileName: string): Observable<any> {
        return this.http.get(this.dbURL + '/' + fileName, {responseType: 'json'});
    }

    getCampaingAll(): Observable<any> {
        return this.http.get(this.APIUrl + '?action=getCampaings', {responseType: 'json', reportProgress: true});
    }

    updateCampaing(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'json', reportProgress: true});
    }

    removeCampaing(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'text', reportProgress: true});
    }

    addNewUser(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'json', reportProgress: true});
    }

    updateUser(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'text', reportProgress: true});
    }

    // getTemplate(data: FormData): Observable<any> {
    //     return this.http.post('http://localhost:4220/rest.php', data, {responseType: 'text', reportProgress: true});
    // }

    getZipFile(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'text', reportProgress: true});
    }

    duplicate(data: FormData): Observable<any> {
        return this.http.post(this.APIUrl, data, {responseType: 'text', reportProgress: true});
    }

    getRecipientsBlobMedia(url): Observable<any> {
        return this.http.get(url, { responseType: 'blob' });
    }

    getImageBlob(url): Observable<any> {
        return this.http.get(this.APIUrl + '?action=getImage&src=' + url, { responseType: 'blob' });
    }

}
