import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Widget } from '../../models/widget';

@Component({
    selector: 'app-wgtm7',
    templateUrl: './wgtm7.component.html',
    styleUrls: ['./wgtm7.component.scss']
})
export class Wgtm7Component implements OnInit {

    @Input()
    wgtData: Widget;

    @Input()
    wgtIndex: number;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    public widgetData: Widget = {
        nid: 'M7',
        name: 'Header',
        icon: './assets/img/m7.svg',
        fields: [
            {
                type: 'text',
                name: 'title',
                label: 'Tytuł',
                placeholder: 'Write header here...',
                value: '',
                required: true

            }
        ],
        background: 'light'
    };


    constructor() {}

    ngOnInit() {
        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.file || this.wgtData.fields) {
            this.widgetData = this.wgtData;
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }

    setBackground(event) {
        if (event.target.checked) {
            this.widgetData.background = 'dark';
        } else {
            this.widgetData.background = 'light';
        }

        this.updateWidget();
    }

}
