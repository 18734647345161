import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from '../shared/models/user';

@Injectable({
    providedIn: 'root'
})
export class IsAdminService implements CanActivate {

    private checked = false;

    constructor(private authService: AuthService, private router: Router) {
        console.log('Goard isAdmin constructor');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const url = state.url;
        if (!this.checked) {
            return this.authService.userObs.subscribe((u: User) => {
                if (u) {
                    if (Object.entries(u).length > 0) {
                        if (u.role === 'admin') {
                            this.router.navigate([url]);
                            this.checked = true;
                        } else {
                            this.router.navigate(['/not-access']);
                            this.checked = true;
                        }
                        return true;
                    } else {

                    }
                }
            });
        } else {
            return true;
        }
        // return true;
    }
}
