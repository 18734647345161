import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Widget } from '../../models/widget';

@Component({
    selector: 'app-wgtm4',
    templateUrl: './wgtm4.component.html',
    styleUrls: ['./wgtm4.component.scss']
})
export class Wgtm4Component implements OnInit {

    @Input()
    wgtData: Widget;

    @Input()
    wgtIndex: number;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    public widgetData: Widget = {
        nid: 'M4',
        name: 'Media + title + lead',
        icon: './assets/img/m4.svg',
        fieldsGroup: [
            {
                group: [
                    {
                        type: 'text',
                        name: 'bullet_1',
                        placeholder: 'Write bullet content here...',
                        label: 'Bullet 1',
                        value: '',
                        required: false
                    },
                    {
                        type: 'link',
                        name: 'bullet_1_url',
                        placeholder: 'Add url...',
                        label: 'Bullet 1 link',
                        value: '',
                        required: false
                    }
                ]
            }
        ]
        // fields: [
        //     {
        //         type: 'text',
        //         name: 'bullet_1',
        //         placeholder: 'Write bullet content here...',
        //         label: 'Bullet 1',
        //         value: '',
        //         required: false
        //     }
        // ]
    };


    constructor() {}

    ngOnInit() {
        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.file || this.wgtData.fieldsGroup) {
            this.widgetData = this.wgtData;
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }

    bulletRemove(group) {
        this.widgetData.fieldsGroup = this.widgetData.fieldsGroup.filter(fg => fg != group);
        this.eventWgtData.emit(this.widgetData);
    }

    addBullet() {
        const num = this.widgetData.fieldsGroup.length+1;
        const group = {
            group: [
                {
                    type: 'text',
                    name: 'bullet_' + num,
                    placeholder: 'Write bullet content here...',
                    label: 'Bullet ' + num,
                    value: '',
                    required: false
                },
                {
                    type: 'link',
                    name: 'bullet_' + num + '_url',
                    placeholder: 'Add url...',
                    label: 'Bullet ' + num + ' link',
                    value: '',
                    required: false
                }
            ]
        };
        this.widgetData.fieldsGroup.push(group);
    }
}
