import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { Campaing } from '../models/campaing';
import { Widget } from '../models/widget';
import { Recipients } from '../models/recipients';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class CampaingService {

    // New single
    private newCampaing: Campaing;
    private components: Widget[];
    private recipients: Recipients[];

    private newCampaingObs = new BehaviorSubject<Campaing>({});
    private componentsObs = new BehaviorSubject<Widget[]>([]);
    private recipientsObs = new BehaviorSubject<Recipients[]>([]);
    private saveStatusObs = new BehaviorSubject<string>('loading');

    // List
    private campaingAll: any[];
    private campaingAllObs = new BehaviorSubject<any[]>([]);

    // Zip file
    private zipFileUrl: string;
    private zipFileUrlObs = new BehaviorSubject<string>('');

    private user: User;

    constructor(private http: HttpService, private authService: AuthService) {
       this.getComponents();
       this.getRecipients();

       this.getAllCampaings();

        this.authService.userObs.subscribe((u: User) => {
            if (u) {
                if (Object.entries(u).length > 0) {
                    this.user = u;
                }
            }
        });
    }

    refreshCampaingList() {
        this.getAllCampaings();
    }

    createCampaing(campaing: Campaing) {
        this.newCampaing = campaing;
        this.newCampaing.created = new Date();
        this.newCampaing.author = this.user.name;
        this.newCampaing.UID = this.user.UID;
        this.newCampaingObs.next(this.newCampaing);
    }

    addWidgets(widgets: Widget[]) {
        this.newCampaing.widgets = widgets;
        this.newCampaingObs.next(this.newCampaing);
    }

    saveCampaing() {
        let postData: FormData = new FormData();

        var data = JSON.stringify(this.newCampaing);
        if (this.newCampaing.id && this.newCampaing.id.length > 0) {
            postData.append('action', 'update-campaing');
        } else {
            postData.append('action', 'create-campaing');
        }
        postData.append("postData", data );

        this.http.getRecipientsBlobMedia(this.newCampaing.recipients.media).subscribe(blob => {
            if (blob instanceof Blob) {

                postData.append("recipients_media", blob );

                if (this.newCampaing.widgets.length > 0) {
                    this.newCampaing.widgets.forEach((widget, index) => {
                        switch(widget.nid) {
                            case 'M1':
                                if (widget.file.value) {
                                    postData.append('image_m1[' + index + ']', widget.file.value );
                                }
                                break;
                            case 'M3':
                                if (widget.file.value) {
                                    postData.append('image_m3[' + index + ']', widget.file.value );
                                }
                                break;
                            case 'M5':
                                if (widget.fieldsGroup.length > 0) {
                                    widget.fieldsGroup.forEach((group, gindex) => {
                                        const field = group['group'][0]['value'];
                                        postData.append('image_m5[' + index + '][' + gindex + ']', field );
                                    });
                                }
                                break;
                            case 'M8':
                                if (widget.file.value) {
                                    postData.append('image_m8[' + index + ']', widget.file.value );
                                }
                                break;
                            case 'M9':
                                if (widget.file.value) {
                                    postData.append('image_m9[' + index + ']', widget.file.value );
                                }
                                break;
                            case 'M10':
                                if (widget.file.value) {
                                    postData.append('image_m10[' + index + ']', widget.file.value );
                                }
                                break;
                            case 'M12':
                                if (widget.file.value) {
                                    postData.append('image_m12[' + index + ']', widget.file.value );
                                }
                                break;
                        }
                    });
                }

                this.http.createDocument(postData).subscribe((resp) => {
                    if (resp !== 'loading') {
                        this.saveStatusObs.next('success');
                        this.newCampaing = {};
                        this.newCampaingObs.next(this.newCampaing);
                    }
                });
            }
        });
    }

    getSaveStatusObs(): Observable<string> {
        return this.saveStatusObs.asObservable();
    }

    resetSaveStatusObs() {
        this.saveStatusObs.next('loading');
    }

    createZip(cid) {
        let postData: FormData = new FormData();

        postData.append('action', 'create-zip');
        postData.append("cid", cid );
        this.http.getZipFile(postData).subscribe(resp => {
            if (resp.length > 0) {
                this.zipFileUrl = resp;
                this.zipFileUrlObs.next(this.zipFileUrl);
            }
        });
    }

    duplicate(cid) {
        let postData: FormData = new FormData();

        postData.append('action', 'duplicate-campaing');
        postData.append("CID", cid );
        this.http.duplicate(postData).subscribe(resp => {
            if (resp.length > 0) {
                console.log('Service: ');
                console.log(resp);
            }
        });
    }

    clearZipFileUrl() {
        this.zipFileUrl = '';
        this.zipFileUrlObs.next(this.zipFileUrl);
    }

    getZipFile(): Observable<string> {
        return this.zipFileUrlObs.asObservable();
    }

    getCampaingObs(): Observable<Campaing> {
        return this.newCampaingObs.asObservable();
    }

    private getComponents(): void {
        this.http.getDBData('components').subscribe((resp: Widget[]) => {
            this.components = resp;
            this.componentsObs.next(this.components);
        });
    }

    getRecipientsObs(): Observable<Recipients[]> {
        return this.recipientsObs.asObservable();
    }

    private getRecipients(): void {
        this.http.getDBData('recipients').subscribe((resp: Recipients[]) => {
            this.recipients = resp.sort(function(a, b) {
                return a.symbol.localeCompare(b.symbol);
             });
            this.recipientsObs.next(this.recipients);
        });
    }

    getComponentsObs(): Observable<Widget[]> {
        return this.componentsObs.asObservable();
    }

    private getAllCampaings() {
        this.http.getCampaingAll().subscribe((resp: any[]) => {
            // console.log(resp);
            if (resp['status'] !== 'loading') {
            // if (this.user) {
                // if (Object.entries(this.user).length > 0) {

                    // if (this.user.role !== 'admin' && this.user.role !== 'manager') {
                    //     this.campaingAll = resp.filter(r => r.UID === this.user.UID);
                    // } else {
                        this.campaingAll = resp;
                    // }
                    this.campaingAllObs.next(this.campaingAll);
                // }
            // }
            }
        });
    }

    addCampaing(campaing) {
        this.campaingAll.push(campaing);
        this.campaingAllObs.next(this.campaingAll);
    }

    getAllCampaingsObs(): Observable<any[]> {
        return this.campaingAllObs.asObservable();
    }

    updateCampaingsObs(campaing: Campaing): Observable<Campaing> {
        let postData: FormData = new FormData();

        var data = JSON.stringify(campaing);
        postData.append('action', 'updateCampaing');
        postData.append("postData", data );

        console.log(data);

        return this.http.updateCampaing(postData);
    }

    updateCampaings(campaing: Campaing) {
        this.newCampaing = campaing;
        this.newCampaing.updated = {
            date: new Date(),
            autor: this.user.name,
            UID: this.user.UID
        };
        console.log(this.newCampaing);
        this.newCampaingObs.next(this.newCampaing);
    }

    removeCampaingsObs(id: string): Observable<string> {
        let postData: FormData = new FormData();

        postData.append('action', 'removeCampaing');
        postData.append("id", id );

        return this.http.removeCampaing(postData);
    }

    editCampaing(cid: string) {
        let postData: FormData = new FormData();

        postData.append('action', 'singleCampaing');
        postData.append("cid", cid );

        console.log('Send req.. servide...');

        this.http.getSingleCampaign(postData).subscribe(campaing => {
            if (campaing) {
                this.newCampaing = campaing;
                this.newCampaingObs.next(this.newCampaing);
                console.log('Edit... servide...');
            }
        });
    }

    clearCampaing() {
        this.newCampaing = {};
        this.newCampaingObs.next(this.newCampaing);
    }

}
