import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaingService } from 'src/app/shared/services/campaing.service';
import { Campaing } from 'src/app/shared/models/campaing';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-step3',
    templateUrl: './step3.component.html',
    styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {

    public campaing: Campaing;

    public editingID: string;

    closeResult: string;
    @ViewChild('success', {static: true}) private successModal;
    @ViewChild('loading', {static: true}) private loadingModal;

    modalOption: NgbModalOptions = {}; // not null!

    constructor(private campaingService: CampaingService, public router:Router, private modalService: NgbModal, private route: ActivatedRoute) {
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;
        
        this.editingID = this.route.snapshot.paramMap.get('cid');
        
        this.campaingService.getCampaingObs().subscribe((campaing: Campaing) => {
            if (Object.keys(campaing).length > 0) {
                this.campaing = campaing;
                console.log(this.campaing);
            } else {
                console.log('Pobieranie nowej kampanii...');
                this.router.navigate(['/add/1']);
            }
        });
    }

    ngOnInit() {
    }

    getStringDate(dateObj, timeObj): string {
        return dateObj['day'] + '-' + dateObj['month'] + '-' + dateObj['year'] + ' ' + timeObj['hour'] + ':' + timeObj['minute'];
    }

    //Modal
    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }

    confirm() {
        this.campaingService.saveCampaing();

        const loadingModal = this.modalService.open(this.loadingModal, this.modalOption);

        this.campaingService.getSaveStatusObs().subscribe(resp => {

            if (resp === 'success') {
                loadingModal.close();
                const openModal = this.modalService.open(this.successModal, this.modalOption);
                setTimeout(() => {
                    this.router.navigate(['/dashboard']);
                    openModal.close();
                    this.campaingService.refreshCampaingList();

                    this.campaingService.resetSaveStatusObs();
                }, 2000);
            }
        });
        
    }

}
