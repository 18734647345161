import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Widget } from '../../models/widget';

@Component({
    selector: 'app-wgtm11',
    templateUrl: './wgtm11.component.html',
    styleUrls: ['./wgtm11.component.scss']
})
export class Wgtm11Component implements OnInit {

    @Input()
    wgtData: Widget;

    @Input()
    wgtIndex: number;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    public widgetData: Widget = {
        nid: 'M11',
        name: 'Event',
        icon: './assets/img/m11.svg',
        fields: [
            {
                type: 'text',
                name: 'title',
                label: 'Tytuł',
                placeholder: 'Write title here...',
                value: '',
                required: true
    
            },
            {
                type: 'text',
                name: 'location',
                label: 'Location',
                placeholder: 'Write location here...',
                value: '',
                required: true
            },
            {
                type: 'text',
                name: 'date',
                label: 'Date',
                placeholder: 'Write date here...',
                value: '',
                required: true
            },
            {
                type: 'text',
                name: 'cta_url',
                placeholder: 'Write cta url here...',
                label: 'CTA URL',
                value: '',
                required: false
            }
        ]
    };


    constructor() {}

    ngOnInit() {
        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.fields) {
            this.widgetData = this.wgtData;
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }
}
