import { Injectable } from '@angular/core';
import { HttpService } from '../shared/services/http.service';
import { User } from '../shared/models/user';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import {map} from 'rxjs/operators';
import crypt from '../../../node_modules/crypto-js';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    readonly sekretKey = 'templateEMG';

    private users: User[];
    public user: User;

    private usersObs = new BehaviorSubject<User[]>([]);
    public userObs = new BehaviorSubject<User>({});

    private loginStatus: string;
    public loginStatusObs = new BehaviorSubject<string>('');

    constructor(private http: HttpService, public router:Router, private route: ActivatedRoute) {
        this.getUsers();

        this.userObs.subscribe((u: User) => {
            this.user = u;
        });
    }

    getUsers() {
        this.http.getDBData('users').subscribe((resp: User[]) => {
            this.users = resp;
            this.usersObs.next(this.users);

            if (localStorage.getItem('asAuth')) {
                const token = localStorage.getItem('asAuth').split('_');
                const uID = token[1];

                this.users.filter((u: User) => {
                    if (u.UID === uID) {
                        this.user = u;
                        this.userObs.next(this.user);
                    }
                });
            } else {
                this.router.navigate(['/login']);
            }
        });
    }

    getUserState() {

    }

    login(email: string, password: string) {
        this.users.filter((u: User) => {
            const enCryptPass = crypt.AES.decrypt(u.password, this.sekretKey).toString(crypt.enc.Utf8);
            if (u.email === email && enCryptPass === password) {
                const UID = u.UID;
                this.user = u;
                localStorage.setItem('asAuth', 'logged_' + UID);
                // this.router.navigate(['/dashboard']);
                this.loginStatus = 'success';
                this.loginStatusObs.next(this.loginStatus);
            } else {
                if (this.loginStatus !== 'success') {
                    this.loginStatus = 'fail';
                    this.loginStatusObs.next(this.loginStatus);
                }

            }
        });
    }

    logout() {
        localStorage.removeItem('asAuth');
        this.user = null;
        this.userObs.next(this.user);
        this.router.navigate(['/login']);
    }
}
