import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaingService } from 'src/app/shared/services/campaing.service';
import { Campaing } from 'src/app/shared/models/campaing';
import { Router, ActivatedRoute } from '@angular/router';
import { Recipients } from 'src/app/shared/models/recipients';
import { ModalDismissReasons, NgbDatepickerConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Widget } from 'src/app/shared/models/widget';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/shared/models/user';

@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit {

    public loading = true;

    public recipients: Recipients[];
    public selectedRecipientId: string;

    public editingID: string;

    step1FormData = new Step1DataForm();

    public isUserAdmin = false;
    public isUserManager = false;
    private user: User;

    private startDateDefault = {};

    closeResult: string;
    @ViewChild('stopModal', {static: true}) private stopModal;

    modalOption: NgbModalOptions = {}; // not null!

    constructor(
        private campaingService: CampaingService, 
        public router:Router, 
        private route: ActivatedRoute, 
        private config: NgbDatepickerConfig,
        private authService: AuthService,
        private modalService: NgbModal) {

            this.modalOption.backdrop = 'static';
            this.modalOption.keyboard = false;


        this.editingID = this.route.snapshot.paramMap.get('cid');

        if (this.editingID) {
            this.campaingService.editCampaing(this.editingID);
            console.log('Edycja...');
        }

        this.campaingService.getCampaingObs().subscribe((campaing: Campaing) => {
            if (Object.keys(campaing).length > 0) {
                this.step1FormData.id = campaing.id ? campaing.id : null;
                this.step1FormData.name = campaing.name;
                this.step1FormData.created = campaing.created;
                this.step1FormData.recipients = campaing.recipients;
                this.step1FormData.subject = campaing.subject;
                this.step1FormData.previewText = campaing.previewText;
                this.step1FormData.startDate = campaing.startDate;
                this.step1FormData.startTime = campaing.startTime;
                this.step1FormData.testMail = campaing.testMail;
                this.step1FormData.widgets = campaing.widgets;
                this.step1FormData.status = campaing.status;
                this.step1FormData.note = campaing.note;
                this.step1FormData.UID = campaing.UID;
                this.step1FormData.author = campaing.author;

                this.selectedRecipientId = this.step1FormData.recipients.rid;

                this.authService.userObs.subscribe((u: User) => {
                    if (u) {
                        if (Object.entries(u).length > 0) {
                            const now = this.setDate(new Date(), 4);
        
                            if (u.role === 'admin') {
                                this.isUserAdmin = true;
                            } else if (u.role === 'manager') {
                                this.isUserManager = true;
                            } else {
                                config.minDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
                                config.outsideDays = 'hidden';
        
                                if(this.step1FormData.status === 'in progress' || this.step1FormData.status === 'done') {
                                    const openModal = this.modalService.open(this.stopModal, this.modalOption);
                                    setTimeout(() => {
                                        this.router.navigate(['/dashboard']);
                                        openModal.close();
                                    }, 5000);
                                }
                            }
        
                            this.startDateDefault = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
        
                            this.user = u;    

                            this.campaingService.getRecipientsObs().subscribe((resp: Recipients[]) => {
                                if (Object.keys(resp).length > 0) {
                    
                                    if (this.isUserAdmin || this.isUserManager) {
                                        this.recipients = resp;
                                    } else {
                                        this.recipients = resp.filter(r => this.user.recipients.includes(r.symbol));
                                    }
                                    
                                } else {
                                    console.log('Pobieranie odbiorców...');
                                }
                                this.loading = false;
                            });
                        }
                    }
                });
            } else {
                console.log('Pobieranie nowej kampanii...');
                this.authService.userObs.subscribe((u: User) => {
                    if (u) {
                        if (Object.entries(u).length > 0) {
                            const now = this.setDate(new Date(), 4);
        
                            if (u.role === 'admin') {
                                this.isUserAdmin = true;
                            } else if (u.role === 'manager') {
                                this.isUserManager = true;
                            } else {
                                config.minDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
                                config.outsideDays = 'hidden';
        
                                if(this.step1FormData.status === 'in progress' || this.step1FormData.status === 'done') {
                                    const openModal = this.modalService.open(this.stopModal, this.modalOption);
                                    setTimeout(() => {
                                        this.router.navigate(['/dashboard']);
                                        openModal.close();
                                    }, 5000);
                                }
                            }
        
                            this.startDateDefault = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
        
                            this.user = u;    

                            this.campaingService.getRecipientsObs().subscribe((resp: Recipients[]) => {
                                if (Object.keys(resp).length > 0) {
                    
                                    if (this.isUserAdmin || this.isUserManager) {
                                        this.recipients = resp;
                                    } else {
                                        this.recipients = resp.filter(r => this.user.recipients.includes(r.symbol));
                                    }
                                    
                                } else {
                                    console.log('Pobieranie odbiorców...');
                                }
                                this.loading = false;
                            });
                        }
                    }
                });
            }
        });
    }

    private setDate(myDate, days) {
        return new Date(myDate.getTime() + days*24*60*60*1000);
        }

    ngOnInit() {
    }

    recipientChange(event) {
        this.step1FormData.recipients = this.recipients.filter((r: Recipients) => r.rid === event.srcElement.value)[0] as Recipients;
    }

    createCampaing(formData) {

        let newCampaing = this.step1FormData as Campaing;

        // newCampaing.recipients = this.recipients.filter((r: Recipients) => r.rid === newCampaing.recipients)[0] as Recipients;

        if (!newCampaing.startDate) {
            newCampaing.startDate = this.startDateDefault;
        }
        if (!newCampaing.startTime) {
            newCampaing.startTime = {
                hour: 10,
                minute: 0,
                second: 0
            }
        }

        if (this.editingID) {
            newCampaing.status = newCampaing.status;
            newCampaing.UID = (this.step1FormData.UID ? this.step1FormData.UID : this.user.UID);
            newCampaing.author = (this.step1FormData.author ? this.step1FormData.author : this.user.name);
            this.campaingService.updateCampaings(newCampaing);
        } else {
            newCampaing.status = 'added';
            this.campaingService.createCampaing(newCampaing);
        }
        
        if (this.editingID) {
            this.router.navigate(['/add/2', this.editingID]);
        } else {
            this.router.navigate(['/add/2']);
        }
    }

    //Modal
    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }
}

class Step1DataForm {
    constructor(
        public id?: string,
        public name?: string,
        public recipients?: Recipients,
        public subject?: string,
        public previewText?: string,
        public startDate?: string,
        public startTime?: string,
        public testMail?: string,
        public widgets?: Widget[],
        public created?: Date,
        public status?: string,
        public note?: string,
        public UID?: string,
        public author?: string
    ) {}
}