import { Component, ViewChild, PipeTransform } from '@angular/core';
import { CampaingService } from '../shared/services/campaing.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { Campaing } from '../shared/models/campaing';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { User } from '../shared/models/user';
import { isSameDay, isSameMonth } from 'date-fns';

import { CalendarEvent, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { Router } from '@angular/router';

import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { DecimalPipe } from '@angular/common';

import { Recipients } from 'src/app/shared/models/recipients';
import { Widget } from '../shared/models/widget';
import { CsvService } from '../shared/services/csv.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DecimalPipe]
})

export class DashboardComponent {

    public loading = true;

    protected uploadURL = environment.uploadURL;

    public recipients: Recipients[];
    step1FormData = new Step1DataForm();
    editingPlaceholder = false;

    public currentDate = new Date();
    public currentYear = this.currentDate.getFullYear();

    public campaigns = [];
    private campaignsRaw = [];
    private campaignsRawBase = [];
    public campaignsRawCalendar = [];
    public isSearchingActive = false;

    public isUserAdmin = false;
    public user: User;

    public monthlyChartData: any[];
    public monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Modal
    closeResult: string;
    @ViewChild('done', {static: true}) private done;
    @ViewChild('success', {static: true}) private successModal;
    @ViewChild('notPermision', {static: true}) private notPermision;
    @ViewChild('addMarkEvent', {static: true}) private addMarkEvent;
    @ViewChild('loadingModal', {static: true}) private loadingModal;

    modalOption: NgbModalOptions = {}; // not null!

    // Table paginator
    page = 1;
    pageSize = 50;
    collectionSize = 0;

    // Calendar
    view = 'list';
    viewDate: Date = new Date();
    viewCampaingByDay: Campaing[] = [];

    viewDayName: CalendarView = CalendarView.Month;

    events: CalendarEvent[];

    activeDayIsOpen: boolean;
    activeDayDate: string;

    // Pie
    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'top',
        },
        plugins: {
        datalabels: {
            formatter: (value, ctx) => {
                const label = ctx.chart.data.labels[ctx.dataIndex];
                return label;
            },
        },
        }
    };
    public pieChartLabels: Label[] = [];
    public pieChartData: number[] = [];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = false;
    public pieChartColors = [
        {
            backgroundColor: [],
        },
    ];

    // Line chart
    public lineChartData: ChartDataSets[] = [{data: [], label: 'Done'}];
    public lineChartLabels: Label[] = this.monthsArr;
    public lineChartOptions: (ChartOptions & { annotation: any }) = {
        responsive: true,
        scales: {
          // We use this empty structure as a placeholder for dynamic theming.
          xAxes: [{}],
          yAxes: []
        },
        annotation: {},
    };
    public lineChartColors: Color[] = [
        { // done
          backgroundColor: 'rgba(40,167,69,0.2)',
          borderColor: 'rgba(40,167,69,1)',
          pointBackgroundColor: 'rgba(40,167,69,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#000',
          pointHoverBorderColor: 'rgba(40,167,69,0.8)'
        }
    ];
    public lineChartLegend = false;
    public lineChartType = 'line';

    public raportRange = {
        dateStart: '',
        dateEnd: ''
    }

    constructor(
        calendar: NgbCalendar,
        private campaingService: CampaingService,
        private modalService: NgbModal,
        private authService: AuthService,
        public router:Router,
        public csvService: CsvService) {
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;

        // this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

        this.authService.userObs.subscribe((u: User) => {
            if (u) {
                if (Object.entries(u).length > 0) {
                    if (u.role === 'admin') {
                        this.isUserAdmin = true;
                    }
                    this.user = u;
                }
            }
        });

        this.getAllCampaing();

        this.campaingService.getRecipientsObs().subscribe((resp: Recipients[]) => {
            if (Object.keys(resp).length > 0) {

                this.recipients = resp;

            } else {
                console.log('Pobieranie odbiorców...');
            }
        });
    }

    private getAllCampaing() {
        this.campaingService.getAllCampaingsObs().subscribe((resp: any[]) => {
            if (Object.keys(resp).length > 0) {

                const sorted = this.getSortableObj(resp.filter((resp: Campaing) => resp.status !== 'placeholder'));

                const toCalendar = [];
                resp.forEach((el, i) => {
                    let sec = String(el.startTime.minute);

                    if (sec.length < 2) {
                        sec = sec + '0';
                    } else {
                        sec = sec;
                    }
                    const dateString = el.startDate['year'] + '-' + el.startDate['month']+'-'+el.startDate['day']+' '+el.startTime.hour+':'+sec;

                    // console.log(el.name + ' = ' + el.id + ' = ' + new Date(dateString) + ' = ' + dateString);

                    const singleRow = {
                        id: ''+el.id,
                        recipients: el.recipients.symbol,
                        title: el.name,
                        status: el.status,
                        campaing: el,
                        color: {
                          primary: el.recipients.color,
                          secondary: '#FAE3E3'
                        },
                        start: new Date(dateString),
                        meta: {
                          incrementsBadgeTotal: true
                        }
                        // Tutaj dodamy warunek dla actions
                    };
                    if (this.isGroupOwner(el.recipients.symbol) && el.status !== 'placeholder') {
                        singleRow['actions'] = [
                            {
                                label: '<img src="../../assets/img/preview.svg" width="18">',
                                onClick: ({ event }: { event: CalendarEvent }): void => {
                                    this.previewTemplate(el.id);
                                }
                            }
                        ]
                    }

                    toCalendar.push(singleRow);
                })



                this.events = toCalendar;

                this.campaigns = sorted;

                this.campaignsRawCalendar = resp;
                this.campaignsRaw = resp.filter((resp: Campaing) => resp.status !== 'placeholder');
                this.campaignsRawBase = this.campaignsRaw;

                this.collectionSize = this.campaignsRaw.length;

                // Lista w kalendarzu
                this.getEventsByDay(this.viewDate);

                // Charts
                this.getDataToLineChart();
                this.getDataToPipeChart();

                // this.getDataByStatus();

                this.loading = false;
            } else {
                // this.loading = false;
                console.log('Pobieranie listy kampanii...');
            }

        });
    }

    // Raport
    hoveredDate: NgbDate | null = null;

    fromDate: NgbDate;
    toDate: NgbDate | null = null;

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
          this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
          this.toDate = date;
        } else {
          this.toDate = null;
          this.fromDate = date;
        }
      }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
        this.getEventsByDay(date);
        this.viewDate = date;
    }

    filtering(event) {
        const text = event.target.value;

        if (text.length > 0) {
            this.isSearchingActive = true;

            const result = this.campaignsRaw.filter(c => {

                const term = text.toLowerCase();
                if (c.name.toLowerCase().includes(term) || c.recipients.symbol.toLowerCase().includes(term) || c.author.toLowerCase().includes(term) || c.status.toLowerCase().includes(term)) {
                    return c;
                }
            });
            this.campaignsRaw = result;
            this.campaigns = this.getSortableObj(result);
            this.collectionSize = this.campaignsRaw.length;
            this.page = 1;
        } else {
            this.isSearchingActive = false;
            this.campaignsRaw = this.campaignsRawBase;
            this.campaigns = this.getSortableObj(this.campaignsRaw);
            this.collectionSize = this.campaignsRaw.length;
            this.page = 1;
        }
    }

    isSchedule(campaing: Campaing): boolean {
        const now = this.setDate(new Date(), 2);
        const doDate = campaing.startDate['year'] + '-' + campaing.startDate['month'] + '-' + campaing.startDate['day'];
        const campaingSchedule = new Date(doDate);

        if (now >= campaingSchedule && campaing.status !== 'done') {
            return true;
        }

        return false;
    }
    private setDate(myDate, days): Date {
        return new Date(myDate.getTime() + days*24*60*60*1000);
    }

    pageChanged(event) {
        this.page = event;
        this.campaigns = this.getSortableObj(this.campaignsRaw);
    }

    pageChangedCount() {
        this.campaigns = this.getSortableObj(this.campaignsRaw);
    }

    getSortableObj(obj) {
        return obj.sort((a, b) => {
            return <any>new Date(b.created) - <any>new Date(a.created);
        })
        .map((c, i) => ({id: i + 1, ...c}))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }

    //Modal
    open(content, event, item) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;

            if (result === 'done') {
                this.makeDone(item);
            }
            if (result === 'delete') {
                this.removeCampaning(item);
            }
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        console.log('close');
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

    previewTemplate(cid: string) {
        window.open(this.uploadURL + '/' + cid + '/index.html', "_blank");
    }

    duplicate(campaing: Campaing) {
        this.campaingService.duplicate(campaing.id);
        setTimeout(() => {
            this.campaingService.refreshCampaingList();
        }, 1000);
        // this.campaingService.getZipFile().subscribe(zipfile => {
        //     if (zipfile.length > 0) {
        //         campaing.status = 'in progress';
        //         this.campaingService.updateCampaingsObs(campaing).subscribe((resp: Campaing) => {
        //             console.log('Updated - status changed');
        //         });
        //         console.log(zipfile);
        //         window.open(this.uploadURL + '/' + zipfile, "_blank");

        //         setTimeout(() => {
        //             this.campaingService.clearZipFileUrl();
        //         }, 1000);
        //     }
        // });
    }

    generateZip(campaing: Campaing) {
        this.campaingService.createZip(campaing.id);
        this.campaingService.getZipFile().subscribe(zipfile => {
            if (zipfile.length > 0) {
                campaing.status = 'in progress';
                this.campaingService.updateCampaingsObs(campaing).subscribe((resp: Campaing) => {
                    console.log('Updated - status changed');
                });
                console.log(zipfile);
                window.open(this.uploadURL + '/' + zipfile, "_blank");

                setTimeout(() => {
                    this.campaingService.clearZipFileUrl();
                }, 1000);
            }
        });
    }

    makeDone(campaing: Campaing) {
        campaing.status = 'done';
        this.campaingService.updateCampaingsObs(campaing).subscribe((resp: Campaing) => {
            console.log(resp);
        });

        const openModal = this.modalService.open(this.successModal, this.modalOption);
        setTimeout(() => {
            openModal.close();
        }, 2000);
    }

    removeCampaning(campaing: Campaing) {
        this.campaingService.removeCampaingsObs(campaing.id).subscribe((resp: string) => {
            if (resp === 'deleted') {
                this.campaigns = this.campaigns.filter((c: Campaing) => c !== campaing);
                this.campaignsRawCalendar = this.campaignsRawCalendar.filter((c: Campaing) => c !== campaing);
                this.campaignsRaw = this.campaignsRaw.filter((c: Campaing) => c !== campaing);

                // Lista w kalendarzu
                this.events = this.events.filter(event => ''+event.id !== campaing.id);
                this.getEventsByDay(this.viewDate);
            }
        });
    }

    getDateAndTime(row) {
        //row.startDate.day + '/' + row.startDate.month + '/' + row.startDate.year + ' ' + row.startTime.hour + ':' + row.startTime.minute
        let dateString = '';
        const month = (row.startDate.month < 10 ? '0'+row.startDate.month : row.startDate.month);
        const day = (row.startDate.day < 10 ? '0'+row.startDate.day : row.startDate.day);

        if (row.startTime) {
            const hour = (row.startTime.hour < 10 ? '0'+row.startTime.hour : row.startTime.hour);
            const minute = (row.startTime.minute < 10 ? '0'+row.startTime.minute : row.startTime.minute);

            dateString = row.startDate.year + '-' + month+'-'+day+' '+hour+':'+minute;
        } else {
            dateString = row.startDate.year + '-' + month+'-'+day;
        }


        return new Date( dateString);
    }

    eventClicked({ event }: { event: CalendarEvent }): void {
        if (this.isGroupOwner(event['recipients'])) {
            if (event['status'] !== 'placeholder') {
                this.router.navigate(['/add/1', event.id]);
            } else {
                //Edycja placeholder
                this.editingPlaceholder = true;

                this.step1FormData.id = event['campaing']['id'] ? event['campaing']['id'] : null;
                this.step1FormData.name = event['campaing']['name'];
                this.step1FormData.created = event['campaing']['created'];
                this.step1FormData.recipients = event['campaing']['recipients'];
                this.step1FormData.subject = event['campaing']['subject'];
                this.step1FormData.startDate = event['campaing']['startDate'];
                this.step1FormData.startTime = event['campaing']['startTime'];
                this.step1FormData.UID = event['campaing']['UID'];
                this.step1FormData.author = event['campaing']['author'];

                this.modalService.open(this.addMarkEvent, this.modalOption);
            }
        } else {
            this.modalService.open(this.notPermision, this.modalOption);
        }
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    createNewCampaing() {
        this.campaingService.clearCampaing();
        this.router.navigate(['/add/1']);
    }

    isGroupOwner(rec): boolean {
        if (this.user) {
            if (Object.entries(this.user).length > 0) {
                if (this.isUserAdmin || this.user.role === 'manager') {
                    return true;
                } else {
                    if (this.user.recipients.includes(rec)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    // Charts
    getDataToLineChart() {
        const result = [
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
            {val: 0},
        ];

        this.campaignsRaw.filter(r => {
            if(this.currentYear === r.startDate['year']) {
                if (r.status === 'done') {
                    result[r.startDate.month-1].val++;
                }
            }
        });

        let arr: any[] = [];
        result.forEach(e => {
            arr.push(e.val);
        });

        this.lineChartData = [{data: arr, label: 'Done'}];
    }

    getDataToPipeChart() {
        const result = [];

        var groupByName = [];

        this.campaignsRaw.forEach(r => {
            if(this.currentYear === r.startDate['year']) {
                groupByName[r.recipients.symbol] = groupByName [r.recipients.symbol] || [];
                groupByName[r.recipients.symbol].push({ color: r.recipients.color });
            }
        });

        let top5 = [];
        for (var key in groupByName) {
            if (key === 'length' || !groupByName.hasOwnProperty(key)) continue;
            top5.push([key, groupByName[key].length, groupByName[key][0]['color']]);
        }
        top5.sort((a, b) =>  {
            return b[1] - a[1];
        });

        let colors = [];
        for (let i = 0; i < 5; i++) {
            const row = top5[i];
            if (row) {
                this.pieChartLabels.push(row[0]);
                this.pieChartData.push(row[1]);
                colors.push(row[2]);
            }
        }

        this.pieChartColors = [{backgroundColor: colors}];
    }

    getDataByStatus() {
        const result = [];

        var groupByName = [];

        this.campaignsRaw.forEach(r => {
            if(r.startDate) {
                if(this.currentYear === r.startDate['year']) {
                    groupByName[r.status] = groupByName [r.status] || [];
                    groupByName[r.status].push({ percent: '' });
                }
            }
        });


        let percents = [];
        for (var key in groupByName) {
            if (key === 'length' || !groupByName.hasOwnProperty(key)) continue;
            percents[key] = [(groupByName[key].length / this.campaignsRaw.length) * 100, groupByName[key].length];
        }

        // console.log(percents);

        return percents;
    }

    addPlaceholder() {
        this.step1FormData = new Step1DataForm();
        this.editingPlaceholder = false;
        this.modalService.open(this.addMarkEvent, this.modalOption);
    }

    private getEventsByDay(date) {
        const today = new Date(date);

        const todayTime = this.getStringDay(today.getFullYear(), today.getMonth()+1, today.getDate());

        this.viewCampaingByDay = [];

        this.campaignsRawCalendar.filter(c => {
            const eventDate = this.getStringDay(c.startDate['year'], c.startDate['month'], c.startDate['day']);
            // const eventDate = new Date(d);

            if(eventDate.toString() === todayTime.toString()) {
                this.viewCampaingByDay.push(c);
            }
        });
    }

    private getStringDay(year, month, day) {
        const m = (month < 10 ? '0'+month : month);
        const d = (day < 10 ? '0'+day : day);

        return year.toString()+m.toString()+d.toString();
    }

    recipientChange(event) {
        this.step1FormData.recipients = this.recipients.filter((r: Recipients) => r.rid === event.srcElement.value)[0] as Recipients;
    }

    createCampaing(formData) {
        let newCampaing = this.step1FormData as Campaing;

        newCampaing.previewText = '';
        newCampaing.testMail = '';
        newCampaing.note = '';
        newCampaing.widgets = [];

        newCampaing.status = 'placeholder';

        if (this.editingPlaceholder) {
            newCampaing.recipients.media = this.getRecipientsMediaByRid(newCampaing.recipients.rid);
            newCampaing.UID = (this.step1FormData.UID ? this.step1FormData.UID : this.user.UID);
            newCampaing.author = (this.step1FormData.author ? this.step1FormData.author : this.user.name);
            this.campaingService.updateCampaings(newCampaing);
        } else {
            newCampaing.startDate = {
                year: this.viewDate.getFullYear(),
                month:  this.viewDate.getMonth() + 1,
                day: this.viewDate.getDate()
            }
            newCampaing.startTime = {
                hour: 10,
                minute: 0,
                second: 0
            }
            this.campaingService.createCampaing(newCampaing);
        }

        this.campaingService.getCampaingObs().subscribe(resp => {
            if (Object.keys(resp).length > 0) {
                //this.campaingService.addCampaing(resp);
            }
        });

        this.campaingService.saveCampaing();

        this.modalService.dismissAll();

        const loadingModal = this.modalService.open(this.loadingModal, this.modalOption);

        this.campaingService.getSaveStatusObs().subscribe(resp => {

            if (resp === 'success') {

                loadingModal.close();
                const openModal = this.modalService.open(this.successModal, this.modalOption);
                setTimeout(() => {
                    // this.router.navigate(['/dashboard']);
                    openModal.close();
                    this.campaingService.refreshCampaingList();

                    this.campaingService.resetSaveStatusObs();

                    this.step1FormData = new Step1DataForm();

                    this.editingPlaceholder = false;
                }, 2000);
            }
        });

        //this.router.navigate(['/add/2']);
    }

    getRecipientsMediaByRid(rid): string {
        let src: Recipients;
        this.campaingService.getRecipientsObs().subscribe((resp: Recipients[]) => {
            if (Object.keys(resp).length > 0) {
                src = resp.filter(r => r.rid === rid)[0];
                return src.media;
            }
        });

        return src.media;
    }

    // Raport
    generateCsv() {
        let data = [];
        let fileName = '';
        this.campaignsRawBase.filter(row => {
            const rowDate = new Date(row.startDate.year+'-'+row.startDate.month+'-'+row.startDate.day);
            const selectedStartDate = new Date(this.raportRange.dateStart['year']+'-'+this.raportRange.dateStart['month']+'-'+this.raportRange.dateStart['day']);
            const selectedEndDate = new Date(this.raportRange.dateEnd['year']+'-'+this.raportRange.dateEnd['month']+'-'+this.raportRange.dateEnd['day']);

            fileName = selectedStartDate.getTime() +'_'+ selectedEndDate.getTime();

            if (rowDate >= selectedStartDate && rowDate <= selectedEndDate) {

                const json = {
                    "Campaign name": row.name,
                    "Recipients": row.recipients.symbol,
                    "Campaign start data": row.startDate.year+'-'+row.startDate.month+'-'+row.startDate.day,
                    "Author": row.author
                }
                data.push(json)
            }
        });
        this.csvService.download(data, 'raport_' + fileName);
    }

    changeStatsYear(e) {
        const year = this.currentYear as number;
        this.currentYear = +year;
        console.log(this.currentYear);
    }
}

class Step1DataForm {
    constructor(
        public id?: string,
        public name?: string,
        public recipients?: Recipients,
        public subject?: string,
        public previewText?: string,
        public startDate?: string,
        public startTime?: string,
        public testMail?: string,
        public widgets?: Widget[],
        public created?: Date,
        public status?: string,
        public note?: string,
        public UID?: string,
        public author?: string
    ) {}
}
