import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Widget } from '../../models/widget';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-wgtm5',
    templateUrl: './wgtm5.component.html',
    styleUrls: ['./wgtm5.component.scss']
})
export class Wgtm5Component implements OnInit {

    protected uploadURL = environment.uploadURL;

    // Image cropper
    public imageChangedEvent: any = '';
    public wgtErrorMessage: string;
    public wgtError: boolean;
    public wgtErrorClass: string;
    
    public cropperConfig = {
        aspectRatio : 2.1,
        dragMode : 'move',
        background : true,
        movable: true,
        rotatable : false,
        scalable: true,
        zoomable: true,
        viewMode: 0, 
        checkImageOrigin : true,
        checkCrossOrigin: true,
        width: 160,
        height: 80,
        croppedWidth: 160,
        croppedHeight: 80,
        canvasWidth: 650,
        canvasHeight: 350
    };

    public groupWidgetIndex = 0;

    // Modal
    closeResult: string;
    @ViewChild('cropper', {static: true}) private cropper;

    @Input()
    wgtData: Widget;

    @Input()
    cid: string;

    @Input()
    wgtIndex: number;

    @Output()
    eventWgtData = new EventEmitter<Widget>();

    @Output()
    eventWgtRemove = new EventEmitter<Widget>();

    imgSrc: any = [];

    public widgetData: Widget = {
        nid: 'M5',
        name: 'Media',
        icon: './assets/img/m5.svg',
        fieldsGroup: [
            {
                group: [
                    {
                        type: 'file',
                        name: 'img',
                        placeholder: '',
                        label: 'Image',
                        size: {
                            w: 160,
                            h: 80
                        },
                        value: '',
                        required: false
                    },
                    {
                        type: 'text',
                        name: 'cta_url',
                        placeholder: 'Write image redirect here...',
                        label: 'Image URL',
                        value: '',
                        required: false
                    },
                    {
                        type: 'text',
                        name: 'cta_alt',
                        placeholder: 'Alternative text...',
                        label: 'Alternative text',
                        value: '',
                        required: false
                    }
                ] 
            }
        ]
    };


    constructor(private modalService: NgbModal, private sanitizer: DomSanitizer, private http: HttpService) {}

    ngOnInit() {
        this.widgetData['WID'] = this.wgtIndex;

        if (this.wgtData.fieldsGroup) {
            this.widgetData = this.wgtData;

            // Editing
            if (this.cid) {
                this.widgetData.fieldsGroup.forEach((field, i) => {
                    if (field.group[0].value) {
                        if (field.group[0].value.length > 0) {
                            const src = field.group[0].value;
                            this.imgURLToBlob(src, i);
                        }
                    }
                });
            }
        }

        this.eventWgtData.emit(this.widgetData);
    }

    updateWidget() {
        this.eventWgtData.emit(this.widgetData);
    }

    eventRemoveWgt() {
        this.eventWgtRemove.emit(this.widgetData);
    }
    
    mediaRemove(group) {
        this.widgetData.fieldsGroup = this.widgetData.fieldsGroup.filter(fg => fg != group);
        this.eventWgtData.emit(this.widgetData);
    }

    addMedia() {
        const num = this.widgetData.fieldsGroup.length+1;
        const group = {
            group: [
                {
                    type: 'file',
                    name: 'img',
                    placeholder: '',
                    label: 'Image',
                    size: {
                        w: 160,
                        h: 80
                    },
                    value: '',
                    required: false
                },
                {
                    type: 'text',
                    name: 'cta_url',
                    placeholder: 'Write image redirect here...',
                    label: 'Image URL',
                    value: '',
                    required: false
                },
                {
                    type: 'text',
                    name: 'cta_alt',
                    placeholder: 'Alternative text...',
                    label: 'Alternative text',
                    value: '',
                    required: false
                }
            ] 
        };
        this.widgetData.fieldsGroup.push(group);
    }

    //Modal
    open(content, index) {
        this.groupWidgetIndex = index;
        this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }
    
     //Cropper
     fileChangeEvent(event: any, index: number): any {
        this.wgtError = false;
        this.wgtErrorMessage = '';
        this.wgtErrorClass = '';

        if (event.target.files && event.target.files[0]) {
            // Size Filter Bytes
            const max_size = 2000000; // 2MB
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 2000;
            const max_width = 2000;
 
            if (event.target.files[0].size > max_size) {
                this.wgtError = true;
                this.wgtErrorMessage = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
                this.wgtErrorClass = 'bg-danger text-white show';
                this.hideError();
                return false;
            }

            if (!allowed_types.includes(event.target.files[0].type)) {
                this.wgtError = true;
                this.wgtErrorMessage = 'Only Images are allowed ( JPG | PNG )';
                this.wgtErrorClass = 'bg-danger text-white show';
                this.hideError();
                return false;
            }
            
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = image.height;
                    const img_width = image.width;
                    
                    if (img_height > max_height || img_width > max_width) {
                        this.wgtError = true;
                        this.wgtErrorMessage = 'Maximum dimentions allowed ' + max_height + 'x' + max_width + 'px';
                        this.wgtErrorClass = 'bg-danger text-white show';
                        this.hideError();
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.imageChangedEvent = imgBase64Path;
                        
                        this.wgtError = false;
                        this.wgtErrorMessage = '';
                        this.wgtErrorClass = '';
                        this.open(this.cropper, index);
                    }
                };
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    private hideError() {
        setTimeout(() => {
            this.wgtError = false;
            this.wgtErrorMessage = '';
            this.wgtErrorClass = '';
        }, 2000);
    }

    base64ToBlob(obj) {
        const BASE64_MARKER = ';base64,';
        const parts = obj.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        
        const blob = new Blob([uInt8Array], { type: contentType });

        this.widgetData.fieldsGroup[this.groupWidgetIndex].group[0].value = blob as any;

        this.modalService.dismissAll();
        this.updateWidget();
    }

    imgURLToBlob(obj, index) {

        const url = this.uploadURL + '/' + this.cid + '/' + obj;

        this.http.getImageBlob(url).subscribe(resp => {
            this.widgetData.fieldsGroup[index].group[0].value = resp as any;
            this.updateWidget();
        });
    }

    isABlob(obj): boolean { 
        return obj.constructor === Blob;
    }

}
